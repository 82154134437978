import { useCallback, useEffect, useState } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { Avatar, Button, Card, CardHeader, Stack, Typography } from "@mui/material";

import { Loader } from "../../../../../Components/Loader";
import {
  ApiActionParametersForm,
  GenericApiActionParametersForm,
} from "../../ApiActionParametersForm/ApiActionParametersForm";
import CloudSpecificParameterForm from "../../ApiActionParametersForm/CloudSpecificParameterForm";
import { ReferencedFieldContextProvider } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { useGetOperationById } from "../../Common/hooks/useGetOperationById";
import { useReferenceableNodes } from "../../Common/hooks/useReferenceableNodes";
import { useUnwrappedApiActionModel } from "../../Common/hooks/useUnwrappedApiActionModel";
import { useNodeEdgeManager } from "../../Common/NodeEdgeManagerProvider";
import { useApiProviderLogo } from "../../Common/utils";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import Approval from "./Approval/Approval";

const APIParametersTab = () => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const {
    operationData: { operation, operationPointer },
    loading: isOperationLoading,
  } = useGetOperationById(nodeConfig.parameters.operation);
  const { modelId, model } = useUnwrappedApiActionModel(operationPointer, operation?.inputModel);

  const providerLogo = useApiProviderLogo();
  const [configurationFormValid, setConfigurationFormValid] = useState<boolean>();
  const [inputModelValid, setInputModelValid] = useState<boolean>();
  const { onChangeActiveNode } = useNodeEdgeManager();

  useEffect(() => {
    updateNode((prevNode) => {
      const errors = { ...prevNode.errors };
      if (configurationFormValid && inputModelValid) {
        delete errors.param_error;
      } else {
        errors.param_error = "Incorrect form parameters";
      }

      return { errors };
    });
  }, [configurationFormValid, inputModelValid, updateNode]);

  const onConfigurationValuesChange = useCallback(
    (configurationValues: unknown) => {
      updateNode((prevNode) => ({
        parameters: { ...prevNode.parameters!, configurationValues: configurationValues as any },
      }));
    },
    [updateNode]
  );

  const onFormValuesChange = useCallback(
    (formValues: unknown) => {
      // NOTE: Can the APIActionForm be updated to know it returns object?
      if (formValues instanceof Object) {
        updateNode((prevNode) => ({ parameters: { ...prevNode.parameters!, formValues } }));
      }
    },
    [updateNode]
  );

  const [referenceableNodes, referenceableNodesLoading] = useReferenceableNodes(nodeConfig.id);

  return (
    <Stack p={2} justifyContent="center" gap={2}>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        Service and action
      </Typography>
      <Card>
        <CardHeader
          avatar={<Avatar src={providerLogo(nodeConfig.parameters.provider)} />}
          subheader={nodeConfig.parameters.operation.service}
          title={nodeConfig.parameters.operation.id}
          titleTypographyProps={{ variant: "body2", textDecoration: "none", fontWeight: 500 }}
          subheaderTypographyProps={{ variant: "caption" }}
          action={
            <Button
              onClick={() => {
                onChangeActiveNode(CloudFlowNodeType.ACTION, nodeConfig.id);
              }}
            >
              Change
            </Button>
          }
          sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
        />
      </Card>
      <Approval />
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
        Parameters
      </Typography>

      <Loader loading={isOperationLoading || modelId !== operation?.inputModel || referenceableNodesLoading}>
        {operation?.parameters && (
          <ApiActionParametersForm
            inputModel={operation.parameters}
            values={nodeConfig.parameters.configurationValues}
            onValidityChange={setConfigurationFormValid}
            onValuesChange={onConfigurationValuesChange}
          >
            <CloudSpecificParameterForm
              inputModel={operation.parameters}
              provider={nodeConfig.parameters.operation.provider}
            />
          </ApiActionParametersForm>
        )}
        {model !== null && (
          <ReferencedFieldContextProvider
            referenceableNodes={referenceableNodes}
            values={nodeConfig.parameters.formValues}
          >
            <GenericApiActionParametersForm
              inputModel={model}
              values={nodeConfig.parameters.formValues}
              onValuesChange={onFormValuesChange}
              onValidityChange={setInputModelValid}
            />
          </ReferencedFieldContextProvider>
        )}
      </Loader>
    </Stack>
  );
};

export default APIParametersTab;

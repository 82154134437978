import "@xyflow/react/dist/style.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { flushSync } from "react-dom";
import { useHistory, useParams } from "react-router";
import {
  CLOUD_FLOW_CREATION_STATUS,
  type CloudflowCreationStatus,
  CloudFlowNodeType,
  NODE_STATUS,
  type NodeParameters,
} from "@doitintl/cmp-models";
import { Box, Stack, useTheme } from "@mui/material";
import {
  Controls,
  type EdgeTypes,
  type Node,
  type NodeTypes,
  ReactFlow,
  ReactFlowProvider,
  useReactFlow,
} from "@xyflow/react";

import { AlgoliaSearchModalBase } from "../../../Components/AlgoliaSearch/AlgoliaSearchModal";
import { AlgoliaIndex, CloudAnalyticsIndex } from "../../../Components/AlgoliaSearch/consts";
import { AlgoliaHooks } from "../../../Context/algoliaContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { ChangeTriggerDialog } from "../Dialog/ChangeTriggerDialog";
import { DeleteIfNodeDialog } from "../Dialog/DeleteIfNodeDialog";
import { ManageIfNodeDialog } from "../Dialog/ManageIfNodeDialog";
import { useCloudflow, useHasActiveCloudflowExecutions, useTriggerCloudflow, useUpdateCloudflow } from "../hooks";
import { EDGE_TYPE, type HandleUpdateNodeFn, type NodeConfigs, type RFNode, type UpdateCloudflowNodes } from "../types";
import { CloudflowAlgoliaResultList } from "./algolia/CloudflowAlgoliaResultList";
import { useCloudflowAlgoliaAutocomplete } from "./algolia/hooks/useCloudflowAlgolia";
import { NodeEdgeManagerProvider, useNodeEdgeManager } from "./Common/NodeEdgeManagerProvider";
import NodeConfigurationPanel from "./ConfigurationPanel/NodeConfigurationPanel";
import ConditionEdge from "./Edge/ConditionEdge";
import CustomEdge from "./Edge/CustomEdge";
import GhostEdge from "./Edge/GhostEdge";
import { ActionNode } from "./Node/ActionNode";
import { ConditionNode } from "./Node/ConditionNode";
import { FilterNode } from "./Node/FilterNode";
import { GhostNode } from "./Node/GhostNode";
import { StartStepConfigurator } from "./Node/StartStepConfigurator";
import { TransformNode } from "./Node/TransformNode";
import { TriggerNode } from "./Node/TriggerNode";
import ConfirmEditDialog from "./Topbar/ConfirmEditDialog";
import ConfirmUnpublishDialog from "./Topbar/ConfirmUnpublishDialog";
import Topbar from "./Topbar/Topbar";
import { mapToUpdateNodePayload } from "./utils/nodeTransformUtils";
import { updateSelectedNode } from "./utils/updateUtils";

const edgeTypes: EdgeTypes = {
  [EDGE_TYPE.CUSTOM]: CustomEdge,
  [EDGE_TYPE.GHOST]: GhostEdge,
  [EDGE_TYPE.CONDITION]: ConditionEdge,
};

const nodeTypes: NodeTypes = {
  [CloudFlowNodeType.START_STEP]: StartStepConfigurator,
  [CloudFlowNodeType.GHOST]: GhostNode,
  [CloudFlowNodeType.TRIGGER]: TriggerNode,
  [CloudFlowNodeType.MANUAL_TRIGGER]: TriggerNode,
  [CloudFlowNodeType.ACTION]: ActionNode,
  [CloudFlowNodeType.CONDITION]: ConditionNode,
  [CloudFlowNodeType.TRANSFORMATION]: TransformNode,
  [CloudFlowNodeType.FILTER]: FilterNode,
};

export const CloudflowEditorContent = () => {
  const theme = useTheme();
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { cloudflow, cloudflowLoading } = useCloudflow(flowId);
  const [updateCloudflow] = useUpdateCloudflow();
  const [triggerCloudflow] = useTriggerCloudflow();
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [confrimUnpublishOpen, setConfirmUnpublishOpen] = useState(false);
  const { customer } = useCustomerContext();
  const history = useHistory();
  const hasActiveExecutions = useHasActiveCloudflowExecutions(flowId);

  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgeClick,
    focusedNodeId,
    showModal,
    closeModal,
    showChangeTriggerDialog,
    closeChangeTriggerDialog,
    manageIfActionsId,
    setManageIfActionsId,
    onSaveManageIfActionsDialog,
    onConfirmDeleteIfNode,
    deleteIfNodeId,
    setDeleteIfNodeId,
    handleNodeOperation,
    onNodeClick,
    activeNode,
    selectNode,
    interactionEnabled,
    onConfirmChangeTrigger,
    onChangeTriggerType,
    updateNodes,
    httpOperationLoading,
    algoliaOperationType,
  } = useNodeEdgeManager();

  const { updateNode } = useReactFlow<Node<RFNode>>();

  const [cloudflowName, setCloudflowName] = useState<string | undefined>();
  const [status, setStatus] = useState<CloudflowCreationStatus | undefined>();
  const handlePanelClose = () => {
    selectNode(null);
  };

  const onClose = () => {
    history.push(`/customers/${customer.id}/cloudflow`);
  };

  const onSaveCloudflow = useCallback(() => {
    const updateNodesPayload: UpdateCloudflowNodes = {
      name: cloudflowName || "",
      flowId,
      updatedNodes: nodes
        .filter((n) => n.type !== CloudFlowNodeType.GHOST && n.data.touched)
        .map((n) => ({
          node: mapToUpdateNodePayload(n),
        }))
        .filter((n) => n.node),
    };
    updateNodes(customer.id, updateNodesPayload);
  }, [cloudflowName, customer.id, flowId, nodes, updateNodes]);

  const onPublishCloudflow = useCallback(
    () =>
      updateCloudflow(customer.id, flowId, {
        status: CLOUD_FLOW_CREATION_STATUS.PUBLISHED,
      }),
    [customer.id, flowId, updateCloudflow]
  );

  const onUnpublishCloudflow = useCallback(async () => {
    await updateCloudflow(customer.id, flowId, {
      status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
    });
    setConfirmUnpublishOpen(false);
  }, [customer.id, flowId, updateCloudflow]);

  const onEditCloudflow = useCallback(async () => {
    await updateCloudflow(customer.id, flowId, {
      status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
    });
    setConfirmEditOpen(false);
  }, [customer.id, flowId, updateCloudflow]);

  const onRunCloudflow = useCallback(async () => {
    await triggerCloudflow(customer.id, flowId);
  }, [customer.id, flowId, triggerCloudflow]);

  const handleUpdateNodeConfig: HandleUpdateNodeFn = useCallback(
    (id, updateNodeAction) => {
      // NOTE: forcing update for third party state here because it causes some useEffects to not run correctly
      flushSync(() => {
        updateNode(id, (node) => updateSelectedNode(node, updateNodeAction));
      });
    },
    [updateNode]
  );

  useEffect(() => {
    if (!cloudflowLoading && cloudflow?.data.name) {
      setCloudflowName(cloudflow.data.name);
    }
    if (!cloudflowLoading && cloudflow?.data.status) {
      setStatus(cloudflow.data.status);
    }
  }, [cloudflow?.data.name, cloudflow?.data.status, cloudflowLoading]);

  const algoliaSearchClient = AlgoliaHooks.useAlgoliaDoerOrUser();
  const [autocomplete, autocompleteState] = useCloudflowAlgoliaAutocomplete({
    searchClient: algoliaSearchClient?.searchClient,
    customHandler: handleNodeOperation,
    focusedNodeId,
    operationType: algoliaOperationType,
  });

  const nodeConfig = useMemo(() => {
    if (!activeNode) {
      return;
    }

    return {
      flowId,
      id: activeNode.id,
      touched: activeNode.data.touched || false,
      name: activeNode.data.nodeData.name,
      parameters: activeNode.data.nodeData.parameters || ({} as unknown as NodeParameters),
      type: activeNode.type as CloudFlowNodeType,
      status: activeNode.data.nodeData.status || NODE_STATUS.VALIDATED,
      transitions: activeNode.data.nodeData.transitions,
      approval: activeNode.data.nodeData.approval,
      errors: activeNode.data.nodeData.errorMessages || {},
    } satisfies NodeConfigs;
  }, [activeNode, flowId]);

  return (
    <>
      <style>
        {`
        #main-layout{
          >[role="alert"],>header {
            display: none;
          }
        }
        `}
      </style>
      <Stack
        sx={{
          backgroundColor: theme.palette.general.backgroundDark,
          mx: -2,
          mt: -1,
          pt: 0,
          pb: 1,
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <ConfirmEditDialog
          isDialogOpened={confirmEditOpen}
          handleCloseDialog={() => {
            setConfirmEditOpen(false);
          }}
          handleEdit={onEditCloudflow}
        />
        <ConfirmUnpublishDialog
          isDialogOpened={confrimUnpublishOpen}
          handleCloseDialog={() => {
            setConfirmUnpublishOpen(false);
          }}
          handleUnpublish={onUnpublishCloudflow}
        />
        <Topbar
          flowTouched={nodes.some((n) => n.data.touched) || cloudflow?.data.name !== cloudflowName}
          cloudflowLoading={cloudflowLoading}
          cloudflowUpdateLoading={httpOperationLoading}
          cloudflowName={cloudflowName}
          cloudflowStatus={status}
          hasActiveExecutions={hasActiveExecutions}
          onUpdateName={(title: string) => {
            setCloudflowName(title);
          }}
          onBlurTitle={() => {
            if (!cloudflowName) {
              setCloudflowName(cloudflow?.data?.name || "");
            }
          }}
          onSaveCloudflow={onSaveCloudflow}
          isPublished={status === CLOUD_FLOW_CREATION_STATUS.PUBLISHED}
          onClose={onClose}
          onEditCloudflow={() => {
            setConfirmEditOpen(true);
          }}
          onUnpublishCloudflow={() => {
            setConfirmUnpublishOpen(true);
          }}
          onRunCloudflow={onRunCloudflow}
          onPublishCloudflow={onPublishCloudflow}
        />
        <Box width="100%" height="100%">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            edgeTypes={edgeTypes}
            onEdgeClick={onEdgeClick}
            onNodeClick={onNodeClick}
            fitView
            fitViewOptions={{ maxZoom: 1 }}
            nodesDraggable={false}
            onlyRenderVisibleElements={true}
            style={{ transition: "margin 0.3s", marginRight: activeNode ? "450px" : "0" }}
            deleteKeyCode={null}
            zoomOnScroll={interactionEnabled}
            panOnDrag={interactionEnabled}
          />
          <Controls orientation="horizontal" showInteractive={false} style={{ direction: "rtl" }} />
        </Box>
      </Stack>
      {!!deleteIfNodeId && (
        <DeleteIfNodeDialog
          open={!!deleteIfNodeId}
          onConfirm={onConfirmDeleteIfNode}
          cloudflowLoading={httpOperationLoading}
          onCancel={() => {
            setDeleteIfNodeId("");
          }}
        />
      )}
      {!!manageIfActionsId && (
        <ManageIfNodeDialog
          open={!!manageIfActionsId}
          onCancel={() => {
            setManageIfActionsId("");
          }}
          onSave={onSaveManageIfActionsDialog}
          cloudflowLoading={httpOperationLoading}
        />
      )}
      {nodeConfig && activeNode?.selected && (
        <NodeConfigurationPanel
          open={!!activeNode}
          onClose={handlePanelClose}
          nodeConfig={nodeConfig}
          onUpdateNode={handleUpdateNodeConfig}
          onChangeTriggerType={onChangeTriggerType}
        />
      )}
      {algoliaSearchClient?.searchClient && showModal && !!focusedNodeId && (
        <AlgoliaSearchModalBase
          autocomplete={autocomplete}
          autocompleteState={autocompleteState}
          setSearchModalVisible={closeModal}
          disableQuickLinks
          localstorageKey="ALGOLIA_FILTER_CLOWDFLOW"
          customTitle="What action do you want to do?"
          CustomResultList={CloudflowAlgoliaResultList}
          restrictedIndices={[...AlgoliaIndex, ...CloudAnalyticsIndex]}
          rowHeight={12.5}
          disableHelpText
        />
      )}
      {nodeConfig && showChangeTriggerDialog && (
        <ChangeTriggerDialog
          nodeConfig={nodeConfig}
          open={!!activeNode}
          onCancel={closeChangeTriggerDialog}
          onConfirm={onConfirmChangeTrigger}
        />
      )}
    </>
  );
};

export const CloudflowEditor = () => (
  <ReactFlowProvider>
    <NodeEdgeManagerProvider>
      <CloudflowEditorContent />
    </NodeEdgeManagerProvider>
  </ReactFlowProvider>
);

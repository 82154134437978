import { useEffect, useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { AssetModel, AssetTypeAwsStandalone, AssetTypeGoogleCloudStandalone } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Button, IconButton } from "@mui/material";

import { useSnackbar } from "../Components/SharedSnackbar/SharedSnackbar.context";
import { createLocationDescriptorWithReferrer } from "../Pages/Entity/hooks";
import { getCachingKeys } from "../utils/cachingKeys";
import { useAssetsContext } from "./customer/AssetContext";
import { useEntitiesContext } from "./customer/EntitiesContext";
import { useCustomerContext } from "./CustomerContext";

export const VerifyEntitiesInvoice = () => {
  const snackbar = useSnackbar();
  const { customer } = useCustomerContext();
  const { loadedAssetsTypes } = useAssetsContext();
  const { entities, entitiesLoading } = useEntitiesContext();

  const query = useMemo(() => {
    if (entitiesLoading) {
      return;
    }

    const filteredAssetTypes = loadedAssetsTypes.filter(
      (assetType) => assetType !== AssetTypeAwsStandalone && assetType !== AssetTypeGoogleCloudStandalone
    );

    if (filteredAssetTypes.length === 0) {
      return;
    }

    const entitiesRef = entities
      .filter((entity) => !entity.customer || !entity.active || entity.invoicing.mode !== "CUSTOM")
      .map((entity) => entity.ref);

    if (entitiesRef.length === 0) {
      return;
    }

    return getCollection(AssetModel)
      .where("customer", "==", customer.ref)
      .where("entity", "in", entitiesRef)
      .where("bucket", "==", null)
      .where("type", "in", filteredAssetTypes)
      .limit(1);
  }, [customer.ref, entities, entitiesLoading, loadedAssetsTypes]);

  const [data] = useCollectionData(query, {
    idField: "id",
    caching: true,
    cachingKeys: getCachingKeys(customer.id),
  });

  useEffect(() => {
    if (!data?.[0]?.entity) {
      return;
    }

    snackbar.onOpen({
      message: "Some of your assets are not assigned to an invoice bucket",
      variant: "warning",
      autoHideDuration: 20000,
      action: [
        <Button
          key="link"
          component={RouterLink}
          to={createLocationDescriptorWithReferrer({
            pathname: `/customers/${customer.ref.id}/entities/${data[0].entity.id}/edit`,
            hash: "invoice-settings",
          })}
          aria-label="Update invoice settings"
          variant="contained"
          onClick={() => {
            snackbar.onClose();
          }}
          color="primary"
        >
          ASSIGN ASSETS
        </Button>,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => {
            snackbar.onClose();
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>,
      ],
    });
  }, [customer.ref.id, data, snackbar]);

  return null;
};

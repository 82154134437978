import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { Alert, Stack, Typography } from "@mui/material";
import { type AxiosError, type AxiosResponse } from "axios";
import { type FormikProps } from "formik";

import { useApiContext } from "../../../api/context";
import { getAxiosErrorMessage } from "../../../utils/axios";
import { useErrorSnackbar } from "../../Integrations/Slack/utils";
import { testClusterConnectionFromPayload } from "../api";
import { type ClusterFormValues, getTestClusterConnectionPayload } from "./clusterForm";

interface TestConnectionProps {
  formik: FormikProps<ClusterFormValues>;
}

const TestConnection = ({ formik }: TestConnectionProps) => {
  const errorSnackbar = useErrorSnackbar();
  const api = useApiContext();

  const [loading, setLoading] = useState(false);
  const [testConnectionError, setTestConnectionError] = useState<string | null>(null);
  const [testConnectionResponse, setTestConnectionResponse] = useState<AxiosResponse | null>(null);

  const handleTestConnection = async () => {
    if (!formik.values.serviceAccountKey) {
      errorSnackbar("To test connection, you must fill in the Service account token field");
      return;
    }

    const payload = getTestClusterConnectionPayload(formik.values);
    setLoading(true);

    try {
      const response = await testClusterConnectionFromPayload(api, payload);

      setTestConnectionError(null);
      setTestConnectionResponse(response);
    } catch (e) {
      const error = getAxiosErrorMessage(e as AxiosError);

      setTestConnectionResponse(null);
      setTestConnectionError(error);
      errorSnackbar(error);
    } finally {
      setLoading(false);
    }
  };

  const getTestConnectionResult = () => {
    const connectionHasBeenTested = testConnectionError !== null || testConnectionResponse !== null;

    if (!connectionHasBeenTested) {
      return null;
    }

    if (testConnectionError) {
      return <Alert severity="error">{testConnectionError}</Alert>;
    }

    const responseHasErrors = testConnectionResponse && Object.keys(testConnectionResponse.data).length > 0;

    if (responseHasErrors) {
      return (
        <Alert severity="error">
          <span>Connection failed:</span>
          <ul>
            {Object.entries(testConnectionResponse?.data).map(([key, value]) => (
              <li key={key}>
                <b>{key}</b>: {String(value)}
              </li>
            ))}
          </ul>
        </Alert>
      );
    }

    return <Alert severity="success">Connection successful</Alert>;
  };

  const isFormTouched = Object.keys(formik.touched).length > 0;

  return (
    <Stack spacing={2}>
      <div>
        <Typography variant="subtitle2">Test Connection</Typography>
        <Typography variant="body2" fontSize={12}>
          Use the button below to test the cluster connection to the DoiT Console
        </Typography>
      </div>
      <LoadingButton
        sx={{ width: "max-content" }}
        variant="outlined"
        onClick={handleTestConnection}
        loading={loading}
        disabled={!isFormTouched || !formik.isValid}
      >
        Test Connection
      </LoadingButton>
      {!loading && getTestConnectionResult()}
    </Stack>
  );
};

export default TestConnection;

import {
  type CloudFlowNodeType,
  type Member,
  ModelType,
  type NodeTransformationConcatenation,
  type NodeTransformationExtract,
  type NodeTransformationFirstItem,
  type NodeTransformationLastItem,
  NodeTransformationType,
  type StructureApiServiceModelDescriptor,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";

import {
  getModelByPath,
  getNodeOutputModel,
  type GetOutputModelForActionNodeFn,
  getRelativePath,
} from "../model-descriptors";
import { type NodeModelWithId } from "../types";

export async function calculateTransformationNodeOutputModel(
  transformationNode: NodeModelWithId<CloudFlowNodeType.TRANSFORMATION>,
  nodes: NodeModelWithId[],
  getOutputModelForActionNode: GetOutputModelForActionNodeFn
) {
  const transformationNodeOutputModel = await getNodeOutputModel(
    getOutputModelForActionNode,
    nodes,
    transformationNode.parameters.referencedNodeId
  );
  if (transformationNodeOutputModel === null) {
    return null;
  }

  let outputModel: UnwrappedApiServiceModelDescriptor = JSON.parse(
    JSON.stringify(getModelByPath(transformationNodeOutputModel, transformationNode.parameters.referencedField))
  );
  for (const transformation of transformationNode.parameters.transformations) {
    switch (transformation.type) {
      case NodeTransformationType.EXTRACT:
      case NodeTransformationType.CONCATENATION: {
        outputModel = appendNewStringFieldModelExtension(outputModel, transformation);
        continue;
      }

      case NodeTransformationType.FIRST_ITEM:
      case NodeTransformationType.LAST_ITEM: {
        outputModel = collectionItemModelExtension(
          outputModel,
          transformation,
          transformationNode.parameters.referencedField
        );
        continue;
      }

      default:
        throw new Error(`Transformation ${JSON.stringify(transformation)} is not implemented yet!`);
    }
  }

  return outputModel;
}

function appendNewStringFieldModelExtension(
  transformationNodeOutputModel: UnwrappedApiServiceModelDescriptor,
  transformation: NodeTransformationConcatenation | NodeTransformationExtract
) {
  const outputModel = extractStructureModel(transformationNodeOutputModel, transformation.type);

  outputModel.members[transformation.newFieldName] = {
    model: {
      type: ModelType.STRING,
    },
  };

  return outputModel;
}

function collectionItemModelExtension(
  transformationNodeOutputModel: UnwrappedApiServiceModelDescriptor,
  transformation: NodeTransformationFirstItem | NodeTransformationLastItem,
  dataSourceReferencedField: string[]
) {
  const outputModel = extractStructureModel(transformationNodeOutputModel, transformation.type);
  const relativePath = getRelativePath(dataSourceReferencedField, transformation.payload);
  const referencedCollectionModel = getModelByPath(transformationNodeOutputModel, relativePath);
  if (referencedCollectionModel.type !== ModelType.LIST) {
    throw new Error(`${transformation.type} transformation node can pick items only from lists`);
  }

  outputModel.members[transformation.newFieldName] = referencedCollectionModel.member;

  return outputModel;
}

function extractStructureModel(
  transformationNodeOutputModel: UnwrappedApiServiceModelDescriptor,
  transformationType: NodeTransformationType
): StructureApiServiceModelDescriptor<Member> {
  let outputModel = transformationNodeOutputModel;
  if (outputModel.type === ModelType.LIST) {
    outputModel = outputModel.member.model;
  }

  if (outputModel.type !== ModelType.STRUCTURE) {
    throw new Error(`${transformationType} transformation node can only reference structures or list of structures`);
  }
  return outputModel;
}

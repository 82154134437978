import { useEffect, useState } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

export const useIsDCIAcceleratorCustomer = (customerId?: string) => {
  const [cohort, setCohort] = useState<string>("");

  useEffect(() => {
    (async () => {
      if (!customerId) {
        return;
      }
      const ref = getCollection(AppModel).doc("support").collection("dciAccelerationCustomers").doc(customerId);

      const doc = await ref.get();

      if (doc.exists()) {
        setCohort("Cohort 1");
      }
    })();
  }, [customerId]);

  return cohort;
};
